import React, {useState, useEffect, useRef} from "react";
import image from "./content_image_2.png"
import empty from "./out-of-stock.png"

import {
  Card,
  List,
  ListItem,
  ListItemSuffix,
  IconButton,
  CardBody,
  Typography,
  CardHeader
} from "@material-tailwind/react";
import axios from "axios";



const TrashIcon = ()=> {
  return (
    <span class="[&>svg]:h-7 [&>svg]:w-7 [&>svg]:fill-[#128c7e]">
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="currentColor"
    viewBox="0 0 448 512">
    <path
      d="M380.9 97.1C339 55.1 283.2 32 223.9 32c-122.4 0-222 99.6-222 222 0 39.1 10.2 77.3 29.6 111L0 480l117.7-30.9c32.4 17.7 68.9 27 106.1 27h.1c122.3 0 224.1-99.6 224.1-222 0-59.3-25.2-115-67.1-157zm-157 341.6c-33.2 0-65.7-8.9-94-25.7l-6.7-4-69.8 18.3L72 359.2l-4.4-7c-18.5-29.4-28.2-63.3-28.2-98.2 0-101.7 82.8-184.5 184.6-184.5 49.3 0 95.6 19.2 130.4 54.1 34.8 34.9 56.2 81.2 56.1 130.5 0 101.8-84.9 184.6-186.6 184.6zm101.2-138.2c-5.5-2.8-32.8-16.2-37.9-18-5.1-1.9-8.8-2.8-12.5 2.8-3.7 5.6-14.3 18-17.6 21.8-3.2 3.7-6.5 4.2-12 1.4-32.6-16.3-54-29.1-75.5-66-5.7-9.8 5.7-9.1 16.3-30.3 1.8-3.7 .9-6.9-.5-9.7-1.4-2.8-12.5-30.1-17.1-41.2-4.5-10.8-9.1-9.3-12.5-9.5-3.2-.2-6.9-.2-10.6-.2-3.7 0-9.7 1.4-14.8 6.9-5.1 5.6-19.4 19-19.4 46.3 0 27.3 19.9 53.7 22.6 57.4 2.8 3.7 39.1 59.7 94.8 83.8 35.2 15.2 49 16.5 66.6 13.9 10.7-1.6 32.8-13.4 37.4-26.4 4.6-13 4.6-24.1 3.2-26.4-1.3-2.5-5-3.9-10.5-6.6z" />
  </svg>
</span>
  );
}




function App() {

  const [position, setPosition] = useState({ latitude: null, longitude: null });
  const [agentNear, setAgentNear] = useState([])
  const mountedRef = useRef() 
  const [value, setValue] = useState(false)                   

  const onClick = (phone) => {
    console.log(phone);
    
    window.location.href = `https://api.whatsapp.com/send?phone=${phone}&text=Hai kak, Mau tanya menu hari ini apa yak?`
  }
  

  useEffect(()=>{
    setPosition({ latitude: null, longitude: null })
  }, [true])


  useEffect(() => {
    
    if ("geolocation" in navigator) {
      navigator.geolocation.getCurrentPosition(function (position) {
        setPosition({
          latitude: position.coords.latitude,
          longitude: position.coords.longitude,
        });
      });
      
      if(position.latitude && position.latitude){
        // console.log("POS@", position);
        
        axios({
          method: 'get',
          url: `https://api.sedapmakmur.com/v1/prod/agent/near/?lat=${position.latitude}&long=${position.longitude}`,
          // withCredentials: false,
          // headers: { 
          //   'Access-Control-Allow-Origin' : '*',
          //   'Access-Control-Allow-Methods':'GET,PUT,POST,DELETE,PATCH,OPTIONS',
          //   'Content-Type': 'application/json'
          // },
        })
        .then((res) =>{
          setAgentNear(res.data.data)
        })
        .catch(err=>{
          setAgentNear([])
        })
        // fetch(`https://api.sedapmakmur.com/v1/prod/agent/near/?lat=${position.latitude}&long=${position.longitude}`)
        // .then((res) => res.json())
        // .then((res) => {
        //   console.log(res);
          
        //   setAgentNear(res.data)
        // })
        // .catch((err) => {
        //   console.log("INI ERRRR",err);
          
        // })
      
      }

      
      
    } else {
      console.log("Geolocation is not available in your browser.");
    }
  }, [position.latitude, position.longitude]);

  
  

  // const menus = [
    
  //   {
  //     _id:3,
  //     name: "Paket Ayam",
  //     price: 10000,
  //     description: "nasi, ayam, sayur, sambal",
  //     image:
  //       "https://sgi-bucket-image.s3.ap-southeast-1.amazonaws.com/images/9abaa688-001c-486f-af73-8d3fc84214b0-Ayam-Woku.png",
  //   },
  //   {
  //     _id:3,
  //     name: "Paket Ikan",
  //     price: 10000,
  //     description: "nasi, ikan, sayur, sambal",
  //     image:
  //       "https://sgi-bucket-image.s3.ap-southeast-1.amazonaws.com/images/9abaa688-001c-486f-af73-8d3fc84214b0-Ayam-Woku.png",
  //   },
  //   {
  //     _id:3,
  //     name: "Paket Cumi",
  //     price: 10000,
  //     description: "nasi, cumi, sayur, sambal",
  //     image:
  //       "https://sgi-bucket-image.s3.ap-southeast-1.amazonaws.com/images/9abaa688-001c-486f-af73-8d3fc84214b0-Ayam-Woku.png",
  //   },
  //   {
  //     _id:3,
  //     name: "Paket Nasi goreng",
  //     price: 10000,
  //     description: "nasi goreng ayam dan acar",
  //     image:
  //       "https://sgi-bucket-image.s3.ap-southeast-1.amazonaws.com/images/9abaa688-001c-486f-af73-8d3fc84214b0-Ayam-Woku.png",
  //   },
    
  // ];
 
  return (
    <div className="bg-BG max-w-xl flex-col-reverse min-h-0 m-auto sm:bg-BG sm:w-auto sm:h-full sm:m-auto">
      {/* <div>
        {console.log("POSSSS", position)
        }
        {position.latitude && position.longitude ? (
          <>
            <p>lat: {position.latitude}</p>
            <p>long: {position.longitude}</p>
          </>
        ) : (
          <p>Loading...</p>
        )}
      </div> */}


      {/* <div className="flex m-auto pb-0 w-full">
        <img
          className= ""
          src={image}
          alt="header-iamge"
         />
      </div> */}
      <div className="flex m-auto pb-0 pt-12 text-7xl sm:text-6xl text-white w-max font-bold font-georgia">
        <h1>SEDAP</h1>
      </div>
      <div className="flex m-auto pt-0 pb-0 text-7xl sm:text-6xl text-white w-max font-bold font-georgia">
        <h1>MAKMUR</h1>
      </div>
      <div className="flex m-auto pt-0 pb-5 text-white w-max text-2xl sm:text-xl">
        <p class="italic m-2">Selalu Dapat Makan Murah</p>
      </div>

     
      <div className="flex m-auto pt-0 text-white items-center justify-center ">
        
        <img
          className="w-4/5"
          src={image}
        />
      
      </div>

      <div className="flex mt-4 m-auto py-2 px-4  w-max text-xl border-white bg-white text-BG rounded-2xl">
        <p class="italic m-2">PESAN SEKARANG</p>
      </div>
      
      
      
      {/* <div className="px-7 grid grid-cols-3 gap-4 sm:grid-cols-2 sm:overflow-scroll h-3/6">
        {menus.map(({ image, name, price, description, _id }, index) => (
          <div key={index}>
            <img
              className="h-40 w-full max-w-full rounded-lg object-cover object-center bg-white"
              src={image}
              alt="gallery-photo"
            />
            <p className="text-sm text-white mt-2">{name}</p>
            <p className="text-xs text-gray-300">*{description}*</p>
            <p className="text-white mb-3 mt-2">Rp. {currencyFormat(price)}</p>
          </div>
          
        ))}
      </div> */}

    

      <div className="m-6 text-white font-bold">
        <h2>Lokasi Terdekat</h2>
      </div>
      
      {/* {console.log("TEST",agentNear)} */}
      {agentNear.length > 0 ? (<div>
        <div className="w-4/4 ml-6 mr-6 pb-12">
        <Card className="bg-white">
          <List>

          {agentNear.map(({name, phone, address, distance}, index) => (
            <ListItem key={index} ripple={false} className="py-2 pr-1 pl-4 border-b-4 border-gray-300">
              <div className="flex flex-col flex-row">
                <div className="mb-1">
                <p className="font-semibold"> Sedap Makmur - ( {name} )</p>
                </div>
             
                <div className="flex">
                  <p className="mr-2 text-green-700 font-semibold">  {distance.toFixed(2)} km </p>
                  <p>- {address} </p>
                </div>
              </div>
              <ListItemSuffix>
                <IconButton variant="text" color="blue-gray" onClick={(e)=>{
                  console.log(phone)
                  
                  onClick(phone)
                }}
                  >
                  <TrashIcon />
                </IconButton>
              </ListItemSuffix>
            </ListItem>
          ))}

            
          </List>
        </Card>
      </div>
      </div>):(
      <div  className=" ml-6 mr-6 pb-12 flex items-center justify-center">
        <Card className="w-full bg-white h-auto">
          <CardBody className="flex items-center justify-center bg-transparent pt-8">
            <img
                src={empty}
                className="h-20 w-20"
              />
          </CardBody>
          <CardBody className="flex items-center justify-center pt-0">
            <Typography >
             
              Tidak ada penjual di sekitar mu ...
            </Typography>
          </CardBody>
        </Card>
      </div>)}
      
      
      
    </div>
  );
}

export default App;
